import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon from "content/favicon.png"
import { lightTheme } from "styles/theme"

const SEO = ({ frontmatter, lang }) => {
  useEffect(() => {
    if(frontmatter && frontmatter.url && (frontmatter.url.indexOf('book-us') !== -1 || frontmatter.url.indexOf('signup') !== -1) ) {
 

      var zi = document.createElement('script');
            zi.type = 'text/javascript';
            zi.async = true;
            zi.referrerPolicy = 'unsafe-url';
            zi.src = 'https://ws.zoominfo.com/pixel/62e2f6695d30f0008e3f7f63';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(zi, s);
      }
  }, [])
  return (
    <Helmet
      htmlAttributes={{
        lang: lang || 'en',
      }}
      title={frontmatter.title}
      titleTemplate={`%s`}
      link={[{ rel: 'canonical', key: frontmatter.url, href: frontmatter.url }]}
      meta={[
        {
          name: "robots",
          content:"noindex,nofollow"
        },
        {
          name: `description`,
          content: frontmatter.description,
        },
        {
          name: `keywords`,
          content: frontmatter.keywords,
        },
        {
          property: `og:title`,
          content: frontmatter.title,
        },
        {
          property: `og:url`,
          content: frontmatter.url,
        },
        {
          property: `og:locale`,
          content: "en_US",
        },
        {
          property: `og:site_name`,
          content: frontmatter.title,
        },
        {
          property: `og:description`,
          content: frontmatter.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: "https://s3.amazonaws.com/img.riminder.net/seo/riminder-artificial-intelligence-for-human-resource.png",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator:id`,
          content: "7999258",
        },
        {
          name: `twitter:creator`,
          content: "HrFlow.ai Engineering",
        },
        {
          name: `twitter:title`,
          content: frontmatter.title,
        },
        {
          name: `google-analytics`,
          content: "UA-177892673-1",
        },
        {
          name: `twitter:description`,
          content: frontmatter.description,
        },
        {
          property: `twitter:site:id`,
          content: '7999258',
        },
        {
          name: `msapplication-TileColor`,
          content: lightTheme.colors.primary,
        },
        {
          name: `msapplication-TileImage`,
          content: `${favicon}`,
        },
        {
          name: `theme-color`,
          content: lightTheme.colors.primary,
        },
        {
          name: `twitter:image:width`,
          content: "1200",
        },
        {
          name: `twitter:image:height`,
          content: "600",
        },
        {
          name: `pjax-timeout`,
          content: '1000',
        },
        {
          name: `twitter:image`,
          content: "https://s3.amazonaws.com/img.riminder.net/seo/riminder-artificial-intelligence-for-human-resource.png",
        },
        {
          name: `twitter:image:src`,
          content: "https://s3.amazonaws.com/img.riminder.net/seo/riminder-artificial-intelligence-for-human-resource.png",
        },
        {
          name: `organization`,
          content: "Hrflow.ai",
        },
        {
          name: `organization url`,
          content: "https://hrflow.ai/",
        },
        {
          name: `organization logo`,
          content: "https://hrflow-ai.imgix.net/logo.svg",
        },
        {
          property: `twitter:site`,
          content: `@hrflowai`,
        },
        {
          property: `fb:app_id`,
          content: "258550260969898",
        },
        {
          "http-equiv": 'cleartype',
          content: 'on'
        },
        {
          name: 'ahrefs-site-verification',
          content: '71814fc707bef1b6ddd31df15af007840f377d44a84c812e18b2e8e6bbe3453d'
        }
      ]}
    />
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.array,
  lang: PropTypes.string,
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
